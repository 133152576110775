export enum AddressTypeIdEnum {
    VisitAddress = "21321311-0000-0000-0000-000000000174",
    PostAddress = "21321311-0000-0000-0000-000000000175",
    InvoiceAddress = "21321311-0000-0000-0000-000000000177",
    RegisteredAddress = "21321311-0000-0000-0000-000000000176"
}


export const AddressTypes = {
    VisitAddress: {
        Id: "21321311-0000-0000-0000-000000000174",
        Name: "Besøksadresse"
    },
    PostAddress: {
        Id: "21321311-0000-0000-0000-000000000175",
        Name: "Postadresse"
    },
    InvoiceAddress: {
        Id: "21321311-0000-0000-0000-000000000177",
        Name: "Fakturaadresse"
    },
    RegisteredAddress: {
        Id: "21321311-0000-0000-0000-000000000176",
        Name: "Folkeregisteradresse"
    },
}

export const AddressTypeArray = [
    {
        Id: "21321311-0000-0000-0000-000000000174",
        Name: "Besøksadresse"
    },
    {
        Id: "21321311-0000-0000-0000-000000000175",
        Name: "Postadresse"
    },
    {
        Id: "21321311-0000-0000-0000-000000000177",
        Name: "Fakturaadresse"
    },
    {
        Id: "21321311-0000-0000-0000-000000000176",
        Name: "Folkeregisteradresse"
    }
]