
export interface IContactSector {
  Id: string;
  Name: string;
  Code: string;
}

type TContactSectorId =
  "E2E9FE9F-B245-4DE3-B341-0535CC97B45F" |
  "B414C24C-CA80-4C32-BFFF-47090DE59C4D" |
  "1321FC0D-718F-4300-9230-7D079348942B" |
  "088B3946-96DA-43A7-95A9-99BB7269D49C" |
  "068AA26E-68AC-4551-9069-AFB8FE237468" |
  "5AF835F8-EC3B-49C8-9DF3-F29F9A6574CC";

export const ContactSectorsArray = [
  { Id: "E2E9FE9F-B245-4DE3-B341-0535CC97B45F", Name: "Person", Code: "P" },
  { Id: "B414C24C-CA80-4C32-BFFF-47090DE59C4D", Name: "Medarbeider", Code: "M" },
  { Id: "1321FC0D-718F-4300-9230-7D079348942B", Name: "Næring", Code: "N" },
  { Id: "088B3946-96DA-43A7-95A9-99BB7269D49C", Name: "Selvstendig", Code: "S" },
  { Id: "068AA26E-68AC-4551-9069-AFB8FE237468", Name: "Forening og annet", Code: "F" },
  { Id: "5AF835F8-EC3B-49C8-9DF3-F29F9A6574CC", Name: "Offentlig", Code: "O" }
]

export const ContactSectors = {
  Person: { Id: "E2E9FE9F-B245-4DE3-B341-0535CC97B45F", Name: "Person", Code: "P" },
  Medarbeider: { Id: "B414C24C-CA80-4C32-BFFF-47090DE59C4D", Name: "Medarbeider", Code: "M" },
  Næring: { Id: "1321FC0D-718F-4300-9230-7D079348942B", Name: "Næring", Code: "N" },
  Selvstendig: { Id: "088B3946-96DA-43A7-95A9-99BB7269D49C", Name: "Selvstendig", Code: "S" },
  ForeningOgAnnet: { Id: "068AA26E-68AC-4551-9069-AFB8FE237468", Name: "Forening og annet", Code: "F" },
  Offentlig: { Id: "5AF835F8-EC3B-49C8-9DF3-F29F9A6574CC", Name: "Offentlig", Code: "O" }
}

export enum ContactSectorByCode {
  P = "Person",
  M = "Medarbeider",
  N = "Næring",
  S = "Selvstendig",
  F = "Forening og annet",
  O = "Offentlig"
}


export function GetContactSectorById(id: string | null): IContactSector | void {
  if (id === null || id === undefined) return
  //@ts-ignore
  return ContactSectorsArray.find(cs => cs.Id.toLocaleLowerCase() === id.toLocaleLowerCase());
}

export const enum ContactSectorIdEnum {
  P = "E2E9FE9F-B245-4DE3-B341-0535CC97B45F",
  M = "B414C24C-CA80-4C32-BFFF-47090DE59C4D",
  N = "1321FC0D-718F-4300-9230-7D079348942B",
  S = "088B3946-96DA-43A7-95A9-99BB7269D49C",
  F = "068AA26E-68AC-4551-9069-AFB8FE237468",
  O = "5AF835F8-EC3B-49C8-9DF3-F29F9A6574CC",
  A = "00000000-0000-0000-0000-000000000000"
}